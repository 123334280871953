.start-date{
    padding-top: 7px;
    font-size: 20px;
    text-align: right;
    font-weight: 700;
}

.end-date{
    padding-top: 7px;
    font-size: 20px;
    text-align: left;
    font-weight: 700;
}
.guest-count{
    font-size: 20px;
    text-align: center;
    font-weight: 700;
}