.filter-data-range .DateRangePicker{
    width: 100%;
}
.filter-data-range .DateRangePickerInput__withBorder{
    border: initial;
}
.filter-data-range .DateRangePickerInput{
    width: 100%;
}
.filter-data-range .DateInput{
    width: 138px !important;
}
.filter-data-range .DateInput_input{
    margin-bottom: 0px !important;
    width: 138px !important;
    padding-right: 20px !important;
}
@media (max-width: 768px) {
    .filter-data-range .DateInput{
        width: 100% !important;
        margin: 5px 0;
    }
    .filter-data-range .DateInput_input{
        margin-bottom: 0px !important;
        width: 100% !important;
        padding-right: 20px !important;
        margin: 5px 0;
    }
    .DateRangePickerInput_arrow_1{
        display: none;
    }
}
.filter-data-range .CalendarDay__selected_span {
    background: #D75353FF !important;

}

.filter-data-range .CalendarDay__selected {
    background: #D75353FF !important;
    color: white;
}
.filter-data-range .CalendarDay__selected_start > div {
    height: 100%;
    line-height: 35px;
    color: black;
    background: linear-gradient(135deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 49%, rgba(255,255,255,1) 49%, rgba(255,255,255,1) 49%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 51%, rgb(215, 83, 83,1) 51%, rgb(215, 83, 83,1) 100%);
}
.filter-data-range .CalendarDay__selected_start .endClose {
    background: linear-gradient(135deg, rgb(202, 11, 11,1) 0%, rgb(202, 11, 11,1) 49%, rgb(202, 11, 11,1) 49%, rgba(255,255,255,1) 49%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 51%, rgb(215, 83, 83,1) 51%, rgb(215, 83, 83,1) 100%) !important;
}
.filter-data-range .CalendarDay__selected_end .startClose {
    background: linear-gradient(315deg, rgb(202, 11, 11,1) 0%, rgb(202, 11, 11,1) 49%, rgb(202, 11, 11,1) 49%, rgba(255,255,255,1) 49%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 51%, rgb(215, 83, 83,1) 51%, rgb(215, 83, 83,1) 100%) !important;
}
.filter-data-range .CalendarDay__selected_end > div {
    height: 100%;
    line-height: 35px;
    color: black;
    background: linear-gradient(315deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 49%, rgba(255,255,255,1) 49%, rgba(255,255,255,1) 49%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 51%, rgb(215, 83, 83,1) 51%, rgb(215, 83, 83,1) 100%);
}
.filter-data-range .CalendarDay__selected:hover {
    background: #D75353FF !important;
    color: white;
}

.filter-data-range .CalendarDay__hovered_span:hover,
.filter-data-range .CalendarDay__hovered_span {
    background: #D75353FF !important;
}

.filter-data-range .DayPicker_weekHeader_li{
    margin-top: 0px !important;
}

.filter-data-range .CalendarDay__blocked_calendar {
    background: rgb(202, 11, 11,1);
    border: 1px solid #cacccd;
    color: #FFFFFF;
}

.startClose{
    height: 100%;
    line-height: 35px;
    background: linear-gradient(315deg, rgb(202, 11, 11) 0%, rgb(202, 11, 11,1) 50%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
}

.endClose{
    height: 100%;
    line-height: 35px;
    background: linear-gradient(135deg, rgb(202, 11, 11,1) 0%, rgb(202, 11, 11,1) 50%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
}

.startEndClose{
    height: 100%;
    line-height: 35px;
    background: rgb(202, 11, 11,1);
    background: linear-gradient(135deg, rgb(202, 11, 11,1) 0%, rgb(202, 11, 11,1) 49%, rgb(202, 11, 11,1) 49%, rgba(255,255,255,1) 49%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 51%, rgba(202,11,11,1) 51%, rgba(202,11,11,1) 100%);
}

