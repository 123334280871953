.home-date-range .DateRangePicker{
    width: 100%;
}
.home-date-range .DateRangePickerInput__withBorder{
    border: initial;
}
.home-date-range .DateRangePickerInput{
    width: 100%;
    background: initial;
}
.home-date-range .DateInput{
    width: 165px !important;
}
.home-date-range .DateInput_input{
    margin-bottom: 0px !important;
    width: 165px !important;
    padding-right: 20px !important;
}
@media (max-width: 768px) {
    .home-date-range .DateInput{
        width: 100% !important;
        padding-bottom: 5px !important;
        background: transparent;
    }
    .home-date-range .DateInput_input{
        margin-bottom: 0px !important;
        width: 100% !important;
        padding-right: 20px !important;
    }
}

.home-date-range .CalendarDay__selected_span {
    background: #ca0b0b;

}

.CalendarDay__selected {
    background: #ca0b0b;
    color: white;
}

.CalendarDay__selected:hover {
    background: #ca0b0b;
    color: white;
}
   
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
    background: #ca0b0b;;
}

.DayPicker_weekHeader_li{
    margin-top: 0px !important;
}

.DayPickerKeyboardShortcuts_show__bottomRight {
    bottom: 0;
    right: 0;
    display: none;
}